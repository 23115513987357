.App-Footer {
  a {
    //color: white;
    text-decoration: none;
  }

  p {
    margin-bottom: 4px;
    //color: white;
    font-weight: bold;
  }

  .center-flex {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
  }

  padding: 16px;
}
