html {
  height: 100%;
}

body {
  margin: 0;
  height: 100%;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

.App {
  height: 100%;
}

/* unvisited link */
a:link {
  color: #9b56b5;
}

/* visited link */
a:visited {
  color: #58adee;
}

/* mouse over link */
a:hover {
  color: #CDABDA;
}

/* selected link */
a:active {
  color: #007ebb;
}

.markdown-render img {
  max-width: 100%;
  height: auto;
}
