[data-color-mode*='dark'] .wmde-markdown,
[data-color-mode*='dark'] .wmde-markdown-var,
.wmde-markdown-var[data-color-mode*='dark'],
.wmde-markdown[data-color-mode*='dark'],
body[data-color-mode*='dark'] {
  color-scheme: dark;
  --color-canvas-default: #343434;
  --color-canvas-subtle: #343434;
  --color-fg-default: white;
}

[data-color-mode*='light'] .wmde-markdown,
[data-color-mode*='light'] .wmde-markdown-var,
.wmde-markdown-var[data-color-mode*='light'],
.wmde-markdown[data-color-mode*='light'],
body[data-color-mode*='light'] {
  color-scheme: light;
  --color-canvas-default: #0000000f
;
  --color-canvas-subtle: #0000000f;
}